/*=========================================================================================
    File Name: demo.scss
    Description: CSS used for demo purpose only. Remove this css from your project.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// Demo content spacing
.demo-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin-right: 1.5rem;
    margin-top: 1.5rem;
  }
}

// Demo content spacing
.demo-vertical-spacing > * {
  margin: 0;
  margin-bottom: 1.5rem;

  &:last-child {
    margin: 0;
  }
}

.demo-spacing-0 > *:last-child {
  margin: 0;
}

// using in icon pages
.icon-wrapper {
  .feather {
    height: 24px;
    width: 24px;
  }
}

.scroll-example {
  padding: 0.5rem;
  position: relative;
  border: 2px solid darken($body-bg, 10%);
  overflow: auto;

  .horz-scroll-content {
    width: 1200px;
  }
}

.browser {
  background: #e0e0e0;
  border: 4px solid #e0e0e0;
  width: 100%;
  height: 12rem;
  padding-top: 20px;
  margin: 0 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.browser iframe {
  border: 0;
  background: #fff;
  height: 100%;
  width: 100%;
}

.loader-wrapper {
  height: 8em;
}

.maintenance-icon {
  font-size: 4rem;
}

.animationIcon {
  right: 30px;
  bottom: 10px;
}

// Breadcrumb page alignment demo
#component-breadcrumbs .breadcrumb:not(last-child) {
  margin-bottom: .5rem;
}

#breadcrumb-alignment {
  .breadcrumb-wrapper {
    box-shadow: 1px 1px 10px rgba($black, 0.1);
    padding: 1px;
    border-radius: $border-radius;

    .breadcrumb {
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }
}

.dark-layout {
  #breadcrumb-alignment {
    .breadcrumb-wrapper {
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24);
    }
  }
}

// Buy now
.buy-now {
  position: fixed;
  bottom: 5%;
  right: 79px;
  z-index: 1031;

  .btn {
    box-shadow: 0 1px 20px 1px $danger !important;

    &:hover {
      box-shadow: none !important;
    }
  }
}
