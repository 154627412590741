$algolia-blue: darken(rgb(0, 174, 255), 7%);
$ghost: rgb(196, 200, 216);
$east-bay: rgb(73, 85, 136);
$koromiko: rgb(255, 193, 104);
$port-gore: rgb(58, 69, 112);

// Global

[class^='ais-'] {
  font-size: 1rem;
  box-sizing: border-box;
}

a[class^='ais-'] {
  text-decoration: none;
}

// Pagination

.ais-Pagination-list {
  display: flex;
  justify-content: space-around;
  font-weight: bold;
}

// Ripple animation
@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: .5;
  }

  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}

// Ripple
.ripple {
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: $orange-600;
    visibility: hidden;
    z-index: 2;
  }

  &:not(:active):before {
    animation: ripple .4s cubic-bezier(0, 0, .2, 1);
    transition: visibility .4s step-end;
  }

  &:active:before {
    visibility: visible;
  }
}

.ais-Hits-list {
  padding: 0;
}
